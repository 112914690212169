import Select from "react-select"
import { CSSProperties, forwardRef, useEffect, useState } from "react"

type OptionsSelect = {
  value: string
  label: string
  disabled?: boolean
  showIcon?: boolean
}

export interface SelectCategoryProps {
  id?: string
  name?: string
  inline?: boolean
  label: string
  success?: boolean
  error?: boolean
  message?: string
  disabled?: boolean
  placeholder: React.ReactNode
  onChange: (event) => void
  onBlur?: (event) => void
  handleInputChange?: (event) => void
  value?: OptionsSelect[] | OptionsSelect
  options: OptionsSelect[]
  hasSelectAll?: boolean
  isLoading?: boolean
  noBorder?: boolean
  customCss?: CSSProperties
  noOptionsMessage?: string
  isSearchable?: boolean
  onlyBorderBottom?: boolean
  menuIsOpen?: boolean
}

const customStyles = (customCss, noBorder = false, error = false, onlyBorderBottom = false) => ({
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: "black",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999, color: "black", width: "175px" }),
  control: (styles, { isDisabled }) => ({
    ...customCss,
    ...styles,
    cursor: isDisabled ? "not-allowed" : "default",
    backgroundColor: isDisabled ? "#E5E7EB" : "white",
    borderColor: error ? "red" : "#D1D5DB",
    borderWidth: noBorder ? 0 : 1,
    borderBottomWidth: (onlyBorderBottom || !noBorder) ? 1 : 0,
    boxShadow: noBorder ? "none" : "initial",
    "&:hover": {
      borderColor: (noBorder && !onlyBorderBottom) ? "transparent" : "rgb(229, 231, 235)",
    },
  }),
  valueContainer: (styles) => ({ ...styles, fontSize: "12px", padding: 0 }),
  placeholder: (styles) => ({ ...styles, fontSize: "12px", color: "gray" }),
  dropdownIndicator: (styles) => ({
    ...styles,
    display: (noBorder && !onlyBorderBottom) ? "none" : "flex",
    fontSize: "12px",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: onlyBorderBottom ? "none" : "flex",
    fontSize: "12px",
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: "black",
    fontSize: "12px",
    // paddingTop: "0.5rem",
    // borderTop: "1px solid #D1D5DB",
    textTransform: "capitalize",
  }),
  menu: provided => ({ ...provided, fontSize: "12px", zIndex: 9999, color: "black" }),
})


const SelectCategory = forwardRef<HTMLInputElement, SelectCategoryProps>(
  (
    {
      id,
      name,
      inline,
      label,
      error,
      message,
      disabled,
      placeholder,
      onChange,
      options,
      value,
      handleInputChange,
      isLoading = false,
      noBorder = false,
      customCss,
      noOptionsMessage = "No Options",
      isSearchable = true,
      onlyBorderBottom = false,
      menuIsOpen = false,
      onBlur,
    }: SelectCategoryProps,
    selectSearchRef,
  ) => {
    const [bodyElement, setBodyElement] = useState(null)
    SelectCategory.displayName = "SelectCategory"
    let inlineStyle = "flex-col"
    let labelStyle = "mb-2"

    if (inline) {
      inlineStyle = "flex-row items-center"
      labelStyle = "mr-2"
    }

    useEffect(() => {
      setBodyElement(document.body)
    }, [])

    return (
      <div className={`flex ${inlineStyle} `}>
        <div className="relative flex-1">
          <Select
            id={id}
            menuPortalTarget={bodyElement}
            instanceId={name}
            options={options && options.length > 0 ? options : []}
            onChange={onChange}
            onInputChange={handleInputChange}
            isDisabled={disabled}
            isSearchable={isSearchable}
            name={name}
            placeholder={placeholder}
            styles={customStyles(customCss, noBorder, error, onlyBorderBottom)}
            value={value}
            isLoading={isLoading}
            aria-label={name}
            noOptionsMessage={() => noOptionsMessage}
            menuIsOpen={menuIsOpen}
            onBlur={onBlur}
          />
        </div>
      </div>
    )
  },
)

export default SelectCategory
