import { createRef, memo, RefObject, useContext, useEffect, useMemo, useRef, useState } from "react"
import { isDesktop } from "react-device-detect"

import Button from "lib/components/Button"
import Text from "lib/components/Text"

import SuggestionSearch from "@components/List/SuggestionSearchList"
import { GetCategoriesQuery } from "@graphql/program/getCategories"
import { ErrorContext } from "context/ErrorContext"
import fetcher from "lib/fetcher"
import Icon from "lib/components/Icons"
import Input from "lib/components/Input"
import { keySelectCategory } from "lib/components/CategorySelector"
import SelectWithSearchIndicator from "lib/components/SelectOption/withSearchIndicator"
import { captureException } from "@sentry/react"
import { LanguageContext } from "context/LanguageContext"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { toast } from "react-toastify"
import useSWR from "swr"
import { handleChar } from "lib/helpers"
import { IconCategoryList } from "@components/Skeleton/SkeletonHomeSearch"
import { gtmEvent } from "lib/gtm"
import { useAuth } from "lib/auth"
import ModalSearchHome from "@components/shared-components/Modal/ModalSearchHome"
import useOutsideClick from "hooks/useOutsideClick"
import SelectCategory from "./Category"
import InputHomeSearch from "./Search"

interface IconTypeProps {
  name: string
  type: "stroke" | "fill"
}

export const LIST_ICONS:IconTypeProps[] = [
  { name:"academic", type: "stroke"}, 
  { name: "squares-2x2", type: "stroke"}, 
  { name: "check-badge", type: "stroke" }, 
  { name: "business", type: "stroke" }, 
  { name: "multiple-users", type: "stroke" }, 
  { name: "briefcase", type: "stroke" },
]

const ImageComponent = dynamic(() => import("lib/components/Image"), {
  ssr: false,
})

export interface SearchBarProps {
  placeholder: string
  onChange?: (event) => void
  onLeft?: (event) => void
  onFocus?: (event) => void
  onClick?: (event) => void
  onRemove?: () => void
  onSearch?: (event) => void
  value?: string
  name: string
  onlySearch?: boolean
  inputSearchRef?: React.Ref<HTMLInputElement>
  handleSearch?,
}

export function SearchBar({
  name = "search",
  placeholder,
  onChange,
  onSearch,
  value,
  onRemove,
  onLeft,
  onFocus,
  handleSearch,
  inputSearchRef,
  onClick,
}: SearchBarProps) {
  return (
    <div className="flex w-full flex-row">
      <form className="flex w-full flex-row items-center gap-4 h-8 sm:h-[40px]" onSubmit={onSearch}>
        <div className="grid w-full grid-cols-12">
          <div className="col-span-12 sm:col-span-11">
            <div className="hidden sm:block">
              <div className="flex">
                <div className="flex-1">
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    type="text"
                    name={name}
                    noBorder
                    onFocus={onFocus}
                    onBlur={onLeft}
                    ref={inputSearchRef}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSearch()
                      }
                    }}
                  />
                </div>

                {value && (
                  <div className="absolute right-0 top-0 flex items-center justify-end w-4 h-10">
                    <div className="rounded-full border" onClick={onRemove} tabIndex={0} role="button">
                      <Icon iconName="close" styles="w-4 h-4" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center sm:hidden">
              <div className="flex-1">
                <InputHomeSearch
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                  type="text"
                  name={name}
                  noBorder
                  onFocus={onFocus}
                  onBlur={onLeft}
                  ref={inputSearchRef}
                  onClick={onClick}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleSearch()
                    }
                  }}
                />
              </div>
              <Button
                customClass="w-9 h-8 sm:h-9 lg:w-[36px] lg:h-[36px] mt-1 block sm:hidden"
                variant="contained"
                color="primary"
                padding="none"
                rounded="large"
                icon="search"
                ariaLabel="search"
                type="button"
                onClick={() => {
                  handleSearch()
                }} />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

function HomeSearch({widthScreen}:{widthScreen?: number}) {
  const { useTranslation: t, handleChangeLanguage, language } = useContext(LanguageContext)
  const [selectedCategory, setSelectedCategory] = useState([
    { value: "all", label: "All Category" },
  ])
  const inputSearchRef: React.Ref<HTMLInputElement> = createRef()
  const [searchValue, setSearchValue] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isModalSearchOpen, setModalSearchOpen] = useState<boolean>(false)
  const [flattenCategory, setFlattenCategory] = useState<
    Array<{
      label: string
      value: string
    }>
  >([])

  const [isSuggestionSearch, setSuggestionSearch] = useState(false)
  const [searchValueArrowKey, setSearchValueArrowKey] = useState("")
  const { data: dataInfographics, error } = useSWR("/api/infographics?name=homepage", fetcher)
  const { data: dataCategory } = useSWR<GetCategoriesQuery>("/api/categories", fetcher)
  const { userProfile, isLoggedIn } = useAuth()
  const divContainerRef: RefObject<HTMLDivElement> = useRef()
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const popularCategories = [
    {
      image: "/assets/images/banking.png",
      title: "Banking & Finance",
      url: "/programs/general-banking",
    },
    {
      image: "/assets/images/bnsp.png",
      title: "BNSP Certification",
      url: "/programs/bnsp-certification",
    },
    {
      image: "/assets/images/technology.png",
      title: "Information Technology & Systems",
      url: "/programs/technology",
    },
    {
      image: "/assets/images/iso.png",
      title: "ISO",
      url: "/programs/iso",
    },
    {
      image: "/assets/images/general.png",
      title: "Leadership & Soft-Skills",
      url: "/programs/leadership-soft-skills",
    },
    {
      image: "/assets/images/hospitality.png",
      title: "Project Management",
      url: "/programs/project-management",
    },
    {
      image: "/assets/images/all-category.png",
      title: "All Categories",
      url: "/programs/all",
    },
  ]

  const router = useRouter()
  const carouselData = []
  const [showMarquee, setShowMarquee] = useState(false)

  const refClickOpen = useOutsideClick(() => {
    setMenuOpen(false)
  })

  useEffect(() => {
    if (localStorage.getItem("marquee_text") !== "hide") {
      if (router.pathname === "/") {
        setShowMarquee(true)
      } else {
        setShowMarquee(false)
      }
    }
  }, [router.pathname])

  useMemo(() => {
    const filterDataCategories = []
    let keySelectorCategory: keySelectCategory = "category_name"
    if (language === "ID") {
      keySelectorCategory = "category_name_indo"
    }
    dataCategory?.program_category
      .filter((category) => category.categories.length === 0)
      .map((category) =>
        filterDataCategories.push({
          label: category[keySelectorCategory] || category.category_name,
          value: category.slug,
        }),
      )
    filterDataCategories.unshift({ label: t("all_categories"), value: "all" })
    setFlattenCategory(filterDataCategories)
    return () => {
      setFlattenCategory([])
    }
  }, [language, t, dataCategory])

  useEffect(() => {
    setSelectedCategory([{ value: "all", label: t("all_categories") }])
  }, [handleChangeLanguage])

  const onFocusClick = () => {
    window.scrollTo(0, 320)
  }

  useEffect(() => {
    if (isSuggestionSearch) {
      document.body.className += "body_hide_scroll"
    } else {
      document.body.classList.remove("body_hide_scroll")
    }
  }, [isSuggestionSearch])

  const handleChangeCategory = (event: { value: string; label: string }) => {
    if (event.value !== "All Category") {
      setSelectedCategory([event])
    }
    setMenuOpen(false)
  }

  function handleSearch() {
    gtmEvent("ce_section_engagement", {
      section_name: t("live_training"),
      button_name: t("search"),
    })
    if (searchValue) {
      return router.push(`/programs/${selectedCategory[0]?.value}?keyword=${searchValue}`)
    }
    return router.push(`/programs/${selectedCategory[0]?.value}`)
  }
  
  function handleSearchSuggestion(event = null) {
    router.push(`/programs/all?keyword=${event}`)
  }

  const handleSubmitSearch = (event) => {
    event.preventDefault()
    const { value } = event.target.search
    if (searchValueArrowKey) {
      return router.push(`/programs/all?keyword=${value}`)
    }
    return router.push(`/programs/${selectedCategory[0]?.value}?keyword=${value}`)
  }

  if (error) {
    const errorMessage =
      error.message || error.response?.data || "Unknown error occurred"
    toast.error(`Oops: ${errorMessage}`)
    captureException(errorMessage)
    return <div />
  }

  popularCategories.map((category, index) =>
    carouselData.push({
      id: 0,
      carousel: (
        <div key={index}>
          <a href={category.url} className="flex flex-col items-center justify-center">
            <ImageComponent src={category.image} width={32} height={32} alt={category.title} />
            <Text alignText="center" customClass="mt-2 text-tiny leading-4 px-2 text-gray-400">
              {category.title}
            </Text>
          </a>
        </div>
      ),
    }),
  )
  let zIndex = "z-10"
  if (isSuggestionSearch) {
    zIndex = "z-40"
  }

  return (
    <div className="flex flex-col">
      <div 
        className={`relative bg-primary 
          ${showMarquee ? 
          `${isLoggedIn && userProfile && !userProfile?.is_verified ? "pt-40" : "pt-32"} sm:pt-36` 
          : `${isLoggedIn && userProfile && !userProfile?.is_verified ? "pt-24" : "pt-16"} sm:pt-24`} pb-8 sm:pb-0`}>
        <div className="container mx-auto">
          <div className="sm:my-auto mx-auto w-full sm:w-2/3">
            <div className="relative z-10">
              <div className="relative">
                <h1 className="text-2xl sm:text-[40px] leading-tight font-semibold text-white text-center">
                  {t("live_training")} <span className="text-[#FFBE0B] leading-tight">{t("marketplace")}</span>
                </h1>
                <Text customClass="text-2xl sm:text-[40px] text-[#FFBE0B] leading-tight" weight="semibold" color="light" alignText="center">
                  <span className="text-white hidden sm:inline">{t("in_indonesia")}</span> <span className="text-white initial sm:hidden">{t("in_indonesia")}</span>
                </Text>
                <Text customClass="mt-4 sm:my-6 px-4" color="light" alignText="center" size={isDesktop ? "medium" : "small"}>
                  {t("discover_training")}
                </Text>
              </div>
            </div>
          </div>
        </div>
        {/* Desktop */}
        <div className={`${zIndex} container mx-auto pb-4 sm:pb-8 px-0 sm:px-20 mt-6 pt-6 sm:pt-0`}>
          <div className="mx-4 grid grid-cols-1 gap-4 rounded-md bg-white p-1.5 pb-2 sm:pb-2 sm:p-2 sm:mx-0 sm:grid-cols-7">
            <div className="hidden items-center gap-x-2 sm:gap-0 sm:block border-r-2 border-[#D1D5DB] col-span-2">
              <div className="w-full mt-0.5">
                <SelectWithSearchIndicator
                  name="category"
                  label=""
                  options={flattenCategory}
                  placeholder={t("all_categories")}
                  value={selectedCategory}
                  onChange={handleChangeCategory}
                  noBorder
                />
              </div>
            </div>
            <div className="sm:col-span-5 relative" ref={divContainerRef}>
              <div className="grid grid-cols-12 gap-0 sm:gap-4 sm:grid-cols-12">
                <div className={`relative flex items-center gap-x-2 sm:hidden ${widthScreen <= 420 ? "col-span-2" : "col-span-1"}`}>
                  {menuOpen && (
                    <div className="absolute top-0 left-0 w-10 z-10">
                      <SelectCategory
                        id="category"
                        name="category"
                        label=""
                        options={flattenCategory}
                        placeholder={t("all_categories")}
                        value={selectedCategory}
                        onChange={handleChangeCategory}
                        noBorder
                        menuIsOpen={menuOpen}
                        onBlur={() => setMenuOpen(false)}
                      />
                    </div>
                  )}
                  <div ref={refClickOpen} role="button" tabIndex={0} className="flex flex-row items-center cursor-pointer z-10 bg-white" onClick={() => setMenuOpen(!menuOpen)}>
                    <Icon iconName="square-2x2" width={26} height={26} type="stroke" />
                    <Icon iconName="chevron-down" width={14} height={14} />
                  </div>
                </div>
                <div className="col-span-1 w-full flex justify-center items-center z-10 sm:hidden">
                  <div className="w-px h-5 bg-gray-400" />
                </div>
                <div className={`relative ${widthScreen <= 420 ? "col-span-9" : "col-span-10"} sm:col-span-10`}>
                  <SearchBar
                    onSearch={handleSubmitSearch}
                    onChange={(e) => {
                      setSearchValueArrowKey("")
                      setSearchValue(e.target.value)
                      setSuggestionSearch(true)
                    }}
                    inputSearchRef={inputSearchRef}
                    placeholder={t("what_do_you_want_to_learn")}
                    name="search"
                    onRemove={() => {
                      setSearchValueArrowKey("")
                      setSearchValue("")
                      setSuggestionSearch(false)
                    }}
                    onFocus={() => {
                      if (isDesktop) {
                        setSuggestionSearch(true)
                        onFocusClick()
                      } else {
                        setSuggestionSearch(false)
                      }
                    }}
                    value={searchValueArrowKey || searchValue}
                    onLeft={() => {
                      setSuggestionSearch(false)
                    }}
                    handleSearch={() => handleSearch()}
                    onClick={() => {
                      setModalSearchOpen(!isModalSearchOpen)
                      if (isModalSearchOpen) {
                        document.body.style.overflow = "hidden"
                      } else {
                        document.body.style.overflow = "scroll"
                      }
                    }}
                  />
                </div>
                <div className="col-span-2 hidden sm:flex sm:initial justify-end items-center">
                  <Button
                    customClass="2xl:w-7/8 w-full "
                    variant="contained"
                    color="primary"
                    padding="large"
                    rounded="large"
                    loading={isLoading}
                    onClick={() => {
                      setIsLoading(!isLoading)
                      handleSearch()
                    }}>
                    {t("search")}
                  </Button>
                </div>
              </div>
              {(isDesktop && isSuggestionSearch) && (
              <SuggestionSearch
                  onFocus={() => setSuggestionSearch(true)}
                  onLeft={() => setSuggestionSearch(false)}
                  searchValue={searchValue.toLocaleLowerCase()}
                  onArrowKey={(programName: string) => {
                    setSearchValueArrowKey(programName)
                  }}
                  selectedCategory={selectedCategory[0].value}
                  inputSearchRef={inputSearchRef}
                  navigateSearch={async (programName) => {
                    handleSearchSuggestion(programName)
                  }}
                  isSuggestionSearch={isSuggestionSearch}
                />
              )}
            </div>
          </div>
        </div>
        <div className="container mx-auto">
          {!dataInfographics ?
            <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 sm:gap-6 py-4 pt-8 sm:pt-8 sm:pb-16 px-4 sm:px-0">
              {[1,2,3,4,5,6].map((item) =>
                <div className="p-[0.8rem] rounded sm:rounded-lg bg-[#FFFFFF14] mx-0 border border-white" key={item}>
                  <IconCategoryList />
                </div>,
              )}
            </div>
            :
            <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 sm:gap-6 py-4 pt-8 sm:pt-8 sm:pb-16 px-4 sm:px-0">
              {dataInfographics?.infographics.map((item, index) =>
                <div className="p-[0.8rem] rounded sm:rounded-lg bg-[#FFFFFF14] mx-0 border border-white" key={item.title}>
                  <div className="flex flex-col items-center gap-2">
                    <Icon iconName={LIST_ICONS[index].name} styles="w-6 sm:w-8 h-6 sm:h-8" type={LIST_ICONS[index].type} color="white" />
                    <Text size="large" weight="semibold" customClass="leading-6" color="light">
                      {item.description}
                    </Text>
                  </div>
                  <div>
                    <Text weight="normal" customClass="leading-6 text-sm sm:text-base" alignText="center" color="light">
                      {t(`${handleChar({ value: item.title, replacement: "_" }).toLocaleLowerCase()}`)}
                    </Text>
                  </div>
                </div>,
              )}
            </div>
           }
        </div>
      </div>
      <ModalSearchHome 
        isOpen={isModalSearchOpen} 
        onClose={() => {
          setModalSearchOpen(false)
        }}
        listCategory={flattenCategory}
        selectedCategory={selectedCategory}
        handleChangeCategory={handleChangeCategory}
      />
    </div>
  )
}

export default memo(HomeSearch)
