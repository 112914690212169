import useSWR from "swr"

import fetcher from "lib/fetcher"
import Button from "lib/components/Button"
import Text from "lib/components/Text"
import { LanguageContext } from "context/LanguageContext"
import { useContext, useEffect, useState } from "react"
import {
  ActivePublicObjects,
  TopRatedProgram,
  TopRatedProgramQuery,
} from "@graphql/homepage/popular_suggestion_program"
import Icon from "lib/components/Icons"
import { NextRouter, useRouter } from "next/router"
import ImageComponent from "lib/components/Image"
import SEO from "lib/SEO"
import SelectCategory from "@components/Sections/Homepage/HomeSearch/Category"
import { capitalize } from "lib/helpers"
import { gtmEvent } from "lib/gtm"
import ContainerModalCustom from "../ModalContainer"

interface Props {
  isOpen: boolean
  selectedCategory?: any
  onClose: () => void
  onChange?: (event) => void
  listCategory?: any
  handleChangeCategory?: (event?) => void
}

type RenderProgramProps = {
  dataProgram: any
  key: number
  router: NextRouter
}

const RenderProgram = ({ dataProgram, key, router }: RenderProgramProps) => {
  let imageUrl: string

  if (dataProgram.type !== "public") {
    imageUrl = dataProgram.program_thumbnail_url || SEO.DEFAULT_OG_IMAGE
  } else {
    imageUrl = dataProgram.custom_image || dataProgram.program_thumbnail_url || SEO.DEFAULT_OG_IMAGE
  }

  return (
    <div
      key={key}
      role="button"
      tabIndex={0}
      className="py-1.5 hover:cursor-pointer z-20 flex flex-row gap-x-2 items-center rounded hover:bg-gray-100"
      onClick={() =>
        router.push(
          dataProgram.type === "public"
            ? `/${dataProgram.category_slug}/${dataProgram.type}/${dataProgram.slug}/${dataProgram.class_code}`
            : `/${dataProgram.category_slug}/${dataProgram.type}/${dataProgram.slug}`,
        )
      }>
      <div className="relative aspect-video h-8 sm:h-11 rounded">
        <ImageComponent
          src={imageUrl}
          alt={dataProgram.program_name}
          rounded
          roundedSize="small"
          objectFit="cover"
          className="aspect-video h-11"
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <Text size="small" customClass="text-xs sm:text-sm font-medium">
          {dataProgram.program_name}
        </Text>
        <div className="flex flex-row items-center">
          <Text size="extra-small" customClass="text-[10px] sm:text-xs font-normal">
            {dataProgram?.tp_name}
          </Text>
          <span className="mx-1 sm:mx-2 text-[10px] sm:text-xs">|</span>
          <Text size="extra-small" customClass="text-[1-px] sm:text-xs font-normal">
            {capitalize(dataProgram.type)}
          </Text>
        </div>
      </div>
    </div>
  )
}

function ModalSearchHome({ isOpen, onClose, onChange, selectedCategory, listCategory, handleChangeCategory }: Props) {
  const router = useRouter()
  const paddingContent = "px-4"
  const paddingSearch = "p-0"

  const { useTranslation: t } = useContext(LanguageContext)

  const [searchValue, setSearchValue] = useState("")
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [suggestionUrl, setSuggestionUrl] = useState(null)
  const [searchUrl, setSearchUrl] = useState(null)

  let categoryQuery = ""
  if (selectedCategory && selectedCategory[0].value !== "all") {
    categoryQuery = `category_slug: {_eq: "${selectedCategory[0].value}"}`
  }

  const { data: dataSearchPrograms, isLoading: loadingSearchTitle } = useSWR(
    searchUrl,
    fetcher,
  )

  const { data: topRatedProgram, isLoading: loadingTopRated } = useSWR<{
    data: TopRatedProgram
    isLoading: boolean
  }>(suggestionUrl, fetcher)

  function handleSearch() {
    gtmEvent("ce_section_engagement", {
      section_name: t("live_training"),
      button_name: t("search"),
    })
    if (searchValue) {
      return router.push(`/programs/${selectedCategory[0]?.value}?keyword=${searchValue}`)
    }
    return router.push(`/programs/${selectedCategory[0]?.value}`)
  }

  useEffect(() => {
    if (searchValue !== "") {
      setSearchUrl(`/api/program/search_program?category=${selectedCategory[0].value || "all"}&search=${searchValue}`)
    }
  }, [searchValue])


  return (
    <ContainerModalCustom
      positionCloseX="left-0"
      customIconCloseX="arrowLeftBack"
      positionTopCloseX="top-2"
      closeModal={onClose}
      isModalOpen={isOpen}
      withArrow={false}>
      <div className="z-10 h-screen w-screen overflow-scroll bg-white">
        <div className="sticky top-0 z-30 bg-white flex flex-row gap-x-2 w-full items-center pl-3.5 py-3">
          <Icon
            iconName="arrowLeftBack"
            color="black"
            onClick={() => {
              setTimeout(() => {
                onClose()
                setMenuOpen(false)
              }, 300)
            }}
            styles="mt-1.5"
          />
          <div className="grid grid-cols-12 gap-0 w-full border rounded">
            <div className="relative flex items-center gap-x-2 flex-row w-full h-10 col-span-2 border-r border-gray-300 rounded-none">
              {menuOpen && (
                <div className="absolute top-0 left-0 z-0">
                  <SelectCategory
                    id="category"
                    name="category"
                    label=""
                    options={listCategory}
                    placeholder={t("all_categories")}
                    value={selectedCategory}
                    onChange={handleChangeCategory}
                    noBorder
                    menuIsOpen={menuOpen}
                    onBlur={() => setMenuOpen(false)}
                  />
                </div>
              )}
              <div role="button" tabIndex={0} className="pl-1 flex flex-1 flex-row items-center cursor-pointer z-20 bg-white" onClick={() => setMenuOpen(!menuOpen)}>
                <Icon iconName="square-2x2" width={22} height={22} type="stroke" />
                <Icon iconName="chevron-down" width={14} height={14} />
              </div>
            </div>
            <div className="relative flex flex-row col-span-10">
              <input
                value={searchValue}
                onChange={(event) => {
                  setSearchValue(event.target.value)
                  onChange?.(event.target.value)
                }}
                name="search-mobile"
                className="w-full border-none px-2 text-sm font-medium leading-5 text-gray-900 outline-none"
                type="text"
                placeholder={t("what_do_you_want_to_learn")}
                autoComplete="off"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleSearch()
                  }
                }}
                onFocus={() => setSuggestionUrl("api/homepage/popular_suggestion_program?limit=20")}
              />
              <div
                role="button"
                tabIndex={0}
                className="bg-primary w-12 h-full flex items-center justify-center rounded-r"
                onClick={handleSearch}
              >
                <Icon iconName="search" width={20} height={20} color="white" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          {isOpen && (
            <style>{`
              .qcw-trigger-btn {
                display: none !important;
              }
            `}</style>
          )}
        </div>
        <div
          className={`bg-white py-2.5 ${paddingContent} border border-gray-50 rounded relative z-10 overflow-y-scroll`}>
          {searchValue === null ? (
            <>
              {topRatedProgram?.data?.active_public.length > 0 && (
                <>
                  <Text customClass="text-sm sm:text-base" weight="semibold">
                    {t("active_public_programs")}
                  </Text>
                  {topRatedProgram?.data?.active_public.map(
                    (it: ActivePublicObjects, indexTopRated: number) => (
                      <RenderProgram dataProgram={it} key={indexTopRated} router={router} />
                    ),
                  )}
                </>
              )}
              {topRatedProgram?.data?.programs.length > 0 && (
                <>
                  <Text customClass="text-sm sm:text-base mt-2" weight="semibold">
                    {t("most_popular_programs")}
                  </Text>
                  {topRatedProgram?.data?.programs.map(
                    (it: TopRatedProgramQuery, indexTopRated: number) => (
                      <RenderProgram dataProgram={it} key={indexTopRated} router={router} />
                    ),
                  )}
                </>
              )}
            </>
          ) : (
            <div className="flex flex-col">
              {dataSearchPrograms?.program_published_program?.map((program, index) => (
                <div
                  key={index}
                  role="button"
                  tabIndex={0}
                  className="p-1.5 hover:cursor-pointer z-20 flex items-center rounded hover:bg-gray-100"
                  onClick={() => {}}>
                  <span className="mr-1.5 opacity-40">
                    <Icon iconName="search" width={24} height={24} />
                  </span>
                  <Text customClass="text-xs sm:text-base font-medium">
                    {program?.program_name}
                  </Text>
                </div>
              ))}
              {dataSearchPrograms?.inhouse?.length > 0 && (
                <>
                  <Text customClass="text-sm sm:text-base mt-2" weight="semibold">
                    {t("in_house_program")}
                  </Text>
                  {dataSearchPrograms?.inhouse?.map((programInhouse, indexInhouse) => (
                    <RenderProgram
                      dataProgram={programInhouse}
                      key={indexInhouse}
                      router={router}
                    />
                  ))}
                </>
              )}
              {dataSearchPrograms?.public?.length > 0 && (
                <>
                  <Text customClass="text-sm sm:text-base mt-2" weight="semibold">
                    {t("programs_public")}
                  </Text>
                  {dataSearchPrograms?.public?.map((programPublic, indexPublic) => (
                    <RenderProgram dataProgram={programPublic} key={indexPublic} router={router} />
                  ))}
                </>
              )}
            </div>
          )}
          {searchValue.length > 0 && (
            <Button
              variant="text"
              color="muted"
              customClass={`${paddingSearch} text-xs sm:text-base mt-1`}
              onClick={() => {}}>
              {`${t("search")} Program/${t("class")} ${t("new_containing")} "${searchValue}"`}
            </Button>
          )}
        </div>
      </div>
    </ContainerModalCustom>
  )
}

export default ModalSearchHome
