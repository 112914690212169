import Icon from "lib/components/Icons"
import Text from "lib/components/Text"
import Tooltips from "lib/components/Tooltips"
import { FormEvent, forwardRef } from "react"

export interface InputHomeSearchProps {
  inline?: boolean
  label?: string
  placeholder?: string
  onChange?: (object) => void
  onBlur?: (object) => void
  onFocus?: (object) => void
  onClick?: (object) => void
  onKeyDown?: (object) => void
  value?: string | number
  name?: string
  disabled?: boolean
  maxLength?: number
  success?: boolean
  error?: boolean
  message?: string | string[]
  labelTooltips?: React.ReactNode
  type: "text" | "number" | "email" | "tel" | "password"
  labelSize?: "medium" | "large"
  defaultValue?: string
  noBorder?: boolean
  size?: "small" | "medium" | "large"
  maxNumber?: number
  onInvalid?: (event: FormEvent<HTMLInputElement>) => void
  customClass?: string
  autoComplete?: "off" | "on" | "new-password" | "email" | "username"
}

const InputHomeSearch = forwardRef<HTMLInputElement, InputHomeSearchProps>(
  (
    {
      inline,
      label,
      placeholder,
      onChange,
      value,
      name,
      labelTooltips,
      type,
      disabled,
      maxLength,
      success,
      error,
      message,
      labelSize = "medium",
      maxNumber,
      defaultValue,
      noBorder = false,
      size = "medium",
      onFocus,
      onBlur,
      onInvalid,
      onClick,
      onKeyDown,
      autoComplete = "off",
    }: InputHomeSearchProps,
    inputRef,
  ) => {
    InputHomeSearch.displayName = "InputHomeSearch"

    const borderColor = "border-gray-200"
    const borderFocus = "border-gray-400"
    const iconType = ""
    const iconColor = "gray"
    const inlineStyle = "flex-col"
    const labelStyle = "flex flex-row items-center mb-2"
    const borderWidth = "border-0"
    let sizeText = ""

    switch (size) {
      case "small":
        sizeText = "text-xs"
        break
      case "large":
        sizeText = "text-lg"
        break
      default:
        break
    }

    return (
      <div className={`flex ${inlineStyle}`}>
        <div className="relative flex-1">
          <input
            className={`${disabled ? "bg-gray-200" : "bg-white"} w-full rounded ${sizeText} ${borderWidth} ${borderColor} p-1.5 focus:outline-none focus:${borderFocus} placeholder:text-xs`}
            placeholder={placeholder}
            onChange={onChange}
            onInvalid={onInvalid}
            value={value}
            name={name}
            disabled={disabled}
            type={type}
            maxLength={maxLength}
            ref={inputRef}
            defaultValue={defaultValue}
            max={maxNumber}
            onWheel={(event) => {
              event.currentTarget.blur()
            }}
            autoComplete={autoComplete}
            onBlur={onBlur}
            onFocus={onFocus}
            onClick={onClick}
            onKeyDown={onKeyDown}
          />

          {iconType && (
            <div className="absolute right-2 top-2 cursor-pointer">
              <Icon iconName={iconType} width={24} height={24} color={iconColor} />
            </div>
          )}
        </div>
        {error && (
          <div className="mt-2">
            <Text type="small-body" color="danger">
              {message}
            </Text>
          </div>
        )}
      </div>
    )
  },
)

export default InputHomeSearch
